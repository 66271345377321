import React from "react"
import PropTypes from "prop-types"
import AboutTemplate from "templates/AboutTemplate"

const AboutUsPage = ({ location }) => (
  <AboutTemplate location={location} lang="EN" />
)

AboutUsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AboutUsPage
